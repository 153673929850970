import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { Oval } from 'react-loader-spinner';
import { useTransition, animated as a, easings } from '@react-spring/web';

import { POPUPS_ENDPOINT } from '../constants';

const Fullview = ({ currentMonitor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [src, setSrc] = useState(POPUPS_ENDPOINT);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoaded(false);
  };

  const handleLoaded = () => setIsLoaded(true);

  useEffect(() => {
    if (currentMonitor === 'center') setSrc(POPUPS_ENDPOINT);
    if (currentMonitor === 'services') setSrc(`${POPUPS_ENDPOINT}/services`);
    if (currentMonitor === 'gallery') setSrc(`${POPUPS_ENDPOINT}/gallery`);
    if (currentMonitor === 'mehanet') setSrc(`${POPUPS_ENDPOINT}/mehanet`);
    if (currentMonitor === 'mehaholding')
      setSrc(`${POPUPS_ENDPOINT}/mehaholding`);
  }, [currentMonitor]);

  const transition = useTransition(currentMonitor, {
    from: { y: 300, x: '-50%', opacity: 0 },
    enter: { y: 0, x: '-50%', opacity: 1 },
    leave: { y: 300, x: '-50%', opacity: 0 },
    config: { duration: 1000, easing: easings.easeInOutBack },
  });

  return (
    <>
      {transition(
        (style, item) =>
          item && (
            <a.button
              style={style}
              className='fixed bottom-[15%] left-1/2 -translate-x-1/2 clip-path-2
              bg-gradient-to-r from-[#1d1d1d] to-[#646464] text-white font-bold 
              py-3 px-6 text-xs z-[100] min-[1100px]:hidden'
              onClick={handleOpen}
            >
              <span
                className='inline-block absolute left-[2px] top-[2px] w-[1px] 
                h-5/6 bg-white'
              >
                <span className='inline-block w-[14px] h-[1px] bg-white absolute top-0 left-0' />
              </span>
              <span
                className='inline-block absolute right-[2px] bottom-[2px] w-[1px] h-5/6
              bg-white rotate-180'
              >
                <span className='inline-block w-[14px] h-[1px] bg-white absolute top-0 left-0' />
              </span>
              <span className='inline-block p-[2px] relative z-10'>full-size view</span>
              <span className='w-10 h-10 bg-white inline-block -z-1 absolute 
              top-1/2 left-1/2 -translate-x-1/2 -translate-y-[40%] rounded-full
              animate-ripple'/>
            </a.button>
          )
      )}
      {isOpen && (
        <div className='fixed top-0 left-0 w-full h-screen bg-white z-[10000]'>
          <button
            className='absolute top-0 left-0 w-12 h-10 clip-path-1 bg-[#d9d9d9]
            flex justify-center items-center'
            onClick={handleClose}
          >
            <X className='w-5 h-5' />
          </button>
          {!isLoaded && (
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
              <Oval
                width={50}
                height={50}
                color='#F64517'
                secondaryColor='#fa8f74'
                strokeWidth={5}
              />
            </div>
          )}
          <iframe
            title='popup'
            src={src}
            className='w-full h-full'
            onLoad={handleLoaded}
          />
        </div>
      )}
    </>
  );
};

export default Fullview;
